import React, { useContext } from 'react';
import Badge from '../Badge';
import './workflow.scss';
import Line1 from '../../img/line1.inline.svg';
import Line2 from '../../img/line2.inline.svg';
import Line3 from '../../img/line3.inline.svg';
import CircleBlue from '../../img/circle-blue.inline.svg';
import CircleOrange from '../../img/circle-orange.inline.svg';
import WorkflowMobile from '../../img/workflow.inline.svg';
import { LangContext } from '../Layout';

const BlueCircle = ({ title, image }) => (
  <div className="circle">
    <div className="circle__image">
      <CircleBlue className="circle__image__container" />
      {image && <img src={image} alt={title} className="circle__image__icon" />}
    </div>
    <div className="circle__title">{title}</div>
  </div>
);

const OrangeCircle = ({ title, image }) => (
  <div className="circle">
    <div className="circle__image">
      <CircleOrange className="circle__image__container" />
      {image && <img src={image} alt={title} className="circle__image__icon" />}
    </div>
    <div className="circle__title">{title}</div>
  </div>
);

const Tool = ({ image, title, desc, index }) => (
  <div className={index % 2 == 0 ? 'tool' : 'tool-middle'}>
    {image && <img src={image.publicURL} alt={title} className="tool__image" />}
    <div className="tool__title">{title}</div>
    <div className="tool__desc">{desc}</div>
  </div>
);

const Workflow = ({ workflow, isCms }) => {
  const { language } = useContext(LangContext);
  return (
    <div className="workflow">
      <div className="workflow__top">
        <Badge text="workflow" />
        <div className="workflow__top__title">
          {workflow['title_' + language]}
        </div>
        <div className="workflow__top__headline">
          {workflow['headline_' + language]}
        </div>
      </div>
      {!isCms && (
        <div className="workflow__mobile-middle">
          <WorkflowMobile className="workflow__mobile-middle__image" />
        </div>
      )}
      {!isCms && (
        <div className="workflow__middle">
          <div className="workflow__middle__row1">
            <Line1 className="workflow__middle__row1__line1" />
            <Line2 className="workflow__middle__row1__line2" />
            <div className="workflow__middle__row1__circles">
              {workflow.steps.map(
                (step, index) =>
                  index < 4 &&
                  (index % 2 == 0 ? (
                    <BlueCircle
                      key={index}
                      title={step['title_' + language]}
                      image={step.image.publicURL}
                    />
                  ) : (
                    <OrangeCircle
                      key={index}
                      title={step['title_' + language]}
                      image={step.image.publicURL}
                    />
                  )),
              )}
            </div>
          </div>
          <div className="workflow__middle__row2">
            <Line2 className="workflow__middle__row2__line2" />
            <Line3 className="workflow__middle__row2__line3" />
            <div className="workflow__middle__row2__circles">
              {workflow.steps.map(
                (step, index) =>
                  index >= 4 &&
                  (index % 2 == 0 ? (
                    <BlueCircle
                      key={index}
                      title={step['title_' + language]}
                      image={step.image.publicURL}
                    />
                  ) : (
                    <OrangeCircle
                      key={index}
                      title={step['title_' + language]}
                      image={step.image.publicURL}
                    />
                  )),
              )}
            </div>
          </div>
        </div>
      )}
      {workflow.tools && (
        <div className="workflow__bottom">
          <div className="workflow__bottom__title">
            {workflow.tools['title_' + language]}
          </div>
          <div className="workflow__bottom__tools">
            {workflow.tools.tool.map((tool, index) => (
              <Tool
                image={tool.image}
                title={tool['title_' + language]}
                desc={tool['desc_' + language]}
                index={index}
                key={index}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Workflow;
