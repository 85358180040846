import React, { useContext } from 'react';
import './read-more.scss';
import { LangContext } from '../Layout';
import { ArrowForward } from '@material-ui/icons';
import { GhostPost } from '../Blog/blog';
import { MediaQuerySSR } from 'react-responsive-ssr';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ReadMore = ({ readMore, title_fr, title_en, posts, tag }) => {
  const { language } = useContext(LangContext);
  if (tag === 'Design')
    posts =
      posts &&
      posts.filter(post =>
        post.node.tags.some(
          ghostTag =>
            ghostTag.name === tag || ghostTag.name === 'Product Strategy',
        ),
      );
  else
    posts =
      posts &&
      posts.filter(post =>
        post.node.tags.some(ghostTag => ghostTag.name === tag),
      );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    centerMode: true,
  };

  return (
    <div className="readmore-container">
      <div className="readmore-container__top">
        <div className="readmore-container__top__title">
          {readMore['title_' + language]}
          <span> {language === 'en' ? title_en : title_fr} </span>
          {' expertise'}
        </div>
        <a
          className="readmore-container__top__link"
          href="https://blog.theodo.ma/"
          target="_blank"
        >
          {readMore['linktext_' + language]}
          <ArrowForward fontSize="small" className="materiel-icon" />
        </a>
      </div>
      <div className="readmore-container__articles">
        <MediaQuerySSR minWidth={1025}>
          {posts
            ? posts
                .slice(0, 3)
                .map((post, index) => (
                  <GhostPost publication={post.node} key={index} />
                ))
            : ''}
        </MediaQuerySSR>
        <MediaQuerySSR maxWidth={1025}>
          <Slider {...settings}>
            {posts
              ? posts.map((post, index) => (
                  <GhostPost publication={post.node} key={index} />
                ))
              : ''}
          </Slider>
        </MediaQuerySSR>
      </div>
    </div>
  );
};

export default ReadMore;
