import React, { useContext } from 'react';
import './content.scss';
import ReactMarkdown from 'react-markdown';
import Badge from '../Badge';
import { LangContext } from '../Layout';

const CaseStudy = ({ caseStudy }) => {
  const { language } = useContext(LangContext);
  return (
    <div>
      {caseStudy.map((caseStudy, index) => (
        <div
          className="study_container"
          key={index}
          style={{ flexFlow: index % 2 != 0 ? 'row-reverse wrap' : 'row wrap' }}
        >
          <div className={'left'}>
            <Badge text={'Case Study'} />
            <div className={'context-title'}>
              {caseStudy['title_' + language]}
            </div>
            <div className={'context-description'}>
              <ReactMarkdown source={caseStudy['description_' + language]} />
            </div>
            <div className={'context-object'}>
              {language === 'en' ? 'Objective' : 'Objectif'}
            </div>
            <div className={'context-desc'}>
              <ReactMarkdown source={caseStudy['objectif_' + language]} />
            </div>
          </div>
          <div className={'right'}>
            <div className={'result'}>
              <div className={'resultat-title'}>
                {language === 'en' ? 'Results' : 'Résultat'}
              </div>
              <div className={'resultat-desc'}>
                <ReactMarkdown source={caseStudy.result['intro_' + language]} />
              </div>
              <div className={'resultat-container'}>
                {caseStudy.result.checks &&
                  caseStudy.result.checks.map((check, index) => (
                    <div className={'result-text'} key={index}>
                      <div className={'text-result'}>
                        <div className={'logo-container'}>
                          <div className={'text-logo'}>
                            <img
                              className={'check-logo'}
                              src={'/img/ch.svg'}
                              alt={'✓'}
                            />
                          </div>
                          <div className={'text-title'}>
                            {check['title_' + language]}
                          </div>
                        </div>
                        <div className={'text-desc'}>
                          <ReactMarkdown
                            source={check['description_' + language]}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className={'resultat-desc'}>
                <ReactMarkdown
                  source={caseStudy.result['conclusion_' + language]}
                />
              </div>
              <div className="technos-row">
                {caseStudy.result.tools.length > 0 && (
                  <div className="technos-row-title"> {'Technologies: '}</div>
                )}
                {caseStudy.result.tools &&
                  caseStudy.result.tools.map(
                    (tool, index) =>
                      tool.image && (
                        <img
                          src={tool.image.publicURL}
                          key={index}
                          className="technos-row__image"
                        />
                      ),
                  )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CaseStudy;
