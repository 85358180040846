import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Brief from '../components/brief/Brief';
import Technos from '../components/technos/Technos';
import CallToAction from '../components/call-to-action/CallToAction';
import ReadMore from '../components/read-more-slider/ReadMore';
import Workflow from '../components/workflow/workflow';
import PSTechnology from '../components/PSTechnology/PSTechnology';
import CaseStudy from '../components/case-study/caseStudy';
import SubMenu from '../components/submenu/Submenu';
import Techs from '../components/techs/techs';

export const ExpertiseTemplate = ({
  brief,
  technologies,
  readMore,
  workflow,
  project,
  pstechnology,
  title_fr,
  title_en,
  posts,
  caseStudy,
  isCms,
  tag,
  techs,
}) => {
  return (
    <section>
      {brief && <Brief data={brief} />}
      {workflow && <Workflow workflow={workflow} isCms={isCms} />}
      {pstechnology && (
        <PSTechnology pstechnology={pstechnology} isCms={isCms} />
      )}
      {technologies && <Technos technos={technologies} />}
      {caseStudy && <CaseStudy caseStudy={caseStudy} />}
      {techs && <Techs techs={techs} tag={tag} />}
      {readMore && (
        <ReadMore
          readMore={readMore}
          title_fr={title_fr}
          title_en={title_en}
          posts={posts}
          tag={tag}
        />
      )}
      {project && <CallToAction project={project} />}
    </section>
  );
};

ExpertiseTemplate.propTypes = {
  brief: PropTypes.object,
  caseStudy: PropTypes.object,
  technologies: PropTypes.object,
  pstechnology: PropTypes.object,
  caseStudy: PropTypes.object,
  readMore: PropTypes.object,
  project: PropTypes.object,
};

const Expertise = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { edges: posts } = data.allGhostPost;
  return (
    <Layout>
      <SubMenu />
      <ExpertiseTemplate
        project={frontmatter.project}
        brief={frontmatter.brief}
        technologies={frontmatter.technologies}
        readMore={frontmatter.readMore}
        caseStudy={frontmatter.caseStudy}
        workflow={frontmatter.workflow}
        pstechnology={frontmatter.pstechnology}
        title_fr={frontmatter.title_fr}
        title_en={frontmatter.title_en}
        tag={frontmatter.tag}
        posts={posts}
        techs={frontmatter.techs}
        isCms={false}
      />
    </Layout>
  );
};

Expertise.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Expertise;

export const pageQuery = graphql`
  query ExpertiseByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        tag
        title_en
        title_fr
        brief {
          title_en
          title_fr
          description_en
          description_fr
          image {
            publicURL
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
        pstechnology {
          title_en
          title_fr
          headline_en
          headline_fr
          close_en
          close_fr
          open_en
          open_fr
          technologies {
            title_en
            title_fr
            desc_en
            desc_fr
            keywords {
              keyword
            }
          }
        }
        techs {
          title_en
          experts_en {
            name_en
            name_fr
            role
            linkedin
            description_en
            description_fr
            image {
              publicURL
            }
          }
        }
        caseStudy {
          title_en
          title_fr
          description_en
          description_fr
          objectif_en
          objectif_fr
          result {
            intro_en
            intro_fr
            checks {
              title_en
              title_fr
              description_en
              description_fr
            }
            conclusion_en
            conclusion_fr
            tools {
              image {
                publicURL
              }
            }
          }
        }
        project {
          title_en
          title_fr
          subtitle_en
          subtitle_fr
          button_en
          button_fr
          image {
            publicURL
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
        technologies {
          title_en
          title_fr
          head {
            title_en
            title_fr
            subtitle_en
            subtitle_fr
          }
          technos {
            title_en
            title_fr
            type_en
            type_fr
            image {
              publicURL
            }
            description_en
            description_fr
          }
        }
        workflow {
          title_en
          title_fr
          headline_en
          headline_fr
          steps {
            title_en
            title_fr
            image {
              publicURL
            }
          }
          tools {
            title_en
            title_fr
            tool {
              title_en
              title_fr
              desc_en
              desc_fr
              image {
                publicURL
              }
            }
          }
        }
        readMore {
          title_en
          title_fr
          linktext_en
          linktext_fr
          link
        }
      }
    }
    allGhostPost(sort: { order: DESC, fields: [published_at] }) {
      edges {
        node {
          id
          slug
          title
          html
          excerpt
          published_at
          feature_image
          image {
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
          authors {
            id
            name
            profile_image
            image {
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
          tags {
            name
            visibility
          }
        }
      }
    }
  }
`;
