import React, { useState } from 'react';
import { Link } from 'gatsby';
import { MediaQuerySSR } from 'react-responsive-ssr';
import './submenu.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  variableWidth: true,
};

const SubMenu = () => {
  const [menuItems, setmenuItems] = useState([
    { name: 'Web', link: 'web' },
    { name: 'Mobile', link: 'mobile' },
    { name: 'Product Design', link: 'product-design' },
  ]);

  return (
    <div className="submenu">
      <MediaQuerySSR minWidth={1025}>
        {menuItems.map((item, index) => (
          <Link
            key={index}
            to={'/expertise/' + item.link}
            className={'submenu__' + (index + 1)}
            activeClassName="submenu__active-item"
          >
            {item.name}
          </Link>
        ))}
        <hr />
      </MediaQuerySSR>
      <MediaQuerySSR maxWidth={1025}>
        <Slider {...settings}>
          {menuItems.map((item, index) => (
            <Link
              key={index}
              to={'/expertise/' + item.link}
              className="submenu__mobile-item"
              activeClassName="submenu__active-item"
            >
              {item.name}
            </Link>
          ))}
        </Slider>
      </MediaQuerySSR>
    </div>
  );
};

export default SubMenu;
