import React, { useContext } from 'react';
import './technos.scss';
import Badge from '../Badge';
import { LangContext } from '../Layout';

const Technos = ({ technos }) => {
  const { language } = useContext(LangContext);

  return technos ? (
    <div className="technos">
      <span className="technos__title">
        <Badge text={technos['title_' + language]} />
      </span>
      <div className={'technos__subtitle__title'}>
        {technos.head['title_' + language]}
      </div>
      <div className={'technos__subtitle__texte'}>
        {technos.head['subtitle_' + language]}
      </div>
      <div className="technos__table" id={'technos'}>
        {technos.technos &&
          technos.technos.map((techno, index) => (
            <div
              className={'technos__table__card'}
              key={index}
              style={{
                borderLeft: (index == 0 || index == 2) && 'none',
                borderRight: (index == 0 || index == 2) && 'none',
                borderTop: 'none',
                borderBottom: 'none',
                width: technos.technos.length > 2 ? '33%' : '49%',
              }}
            >
              {techno.image && (
                <div
                  className="technos__table__containerImage"
                  style={{ paddingTop: index == 2 && 20 }}
                >
                  <img
                    src={techno.image.publicURL}
                    alt={techno['title_' + language]}
                    className="technos__table__techno"
                  />
                </div>
              )}
              <div className={'technos__table__card__content'}>
                <div className={'technos__table__card__desc'}>
                  <div className={'technos__table__card__desc__top'}>
                    <div className={'technos__table__card__desc__top__name'}>
                      {techno['title_' + language]}
                    </div>
                    <div className={'technos__table__card__desc__top__type'}>
                      {techno['type_' + language]}
                    </div>
                  </div>
                </div>
                <div className={'technos__table__card__texte'}>
                  {techno['description_' + language]}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  ) : null;
};
export default Technos;
