import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './brief.scss';
import { LangContext } from '../Layout';
import { ArrowForward } from '@material-ui/icons';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';

const Brief = ({ data, title }) => {
  const { language } = useContext(LangContext);
  return (
    <div className="brief">
      <div className="brief-left">
        <div className={'brief-content'}>
          <h3 className="brief-title">{data['title_' + language]}</h3>
          <p className="brief-description">
            <ReactMarkdown source={data['description_' + language]} />
          </p>
          {title !== 'Methodology' && (
            <Link to="/contact/#discuss">
              <button>
                Start my project
                <ArrowForward fontSize="small" className="materiel-icon" />
              </button>
            </Link>
          )}
        </div>
      </div>

      <div className="brief-right">
        {data.image && (
          <>
            <div className="brief-desktop">
              {data.image.childImageSharp && (
                <Img
                  className={'brief-image'}
                  fluid={data.image.childImageSharp.fluid}
                  alt={data['title_' + language]}
                  style={{ position: 'absolute' }}
                />
              )}
            </div>
            <div className="brief-mobile">
              {data.image.childImageSharp && (
                <Img
                  className={'brief-image'}
                  fluid={data.image.childImageSharp.fluid}
                  alt={data['title_' + language]}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Brief.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.object,
    description: PropTypes.string,
  }),
};

export default Brief;
