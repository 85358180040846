import React from 'react';
import './style.scss';
import Badge from '../Badge';
import { MediaQuerySSR } from 'react-responsive-ssr';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Experts from '../experts/experts';
//TODO: need some refactor in styles because there is duplicationand naming is not good
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  arrows: false,
  centerMode: true,
};
const Techs = ({ techs, tag }) => {
  const [pressedIndex, setPressedIndex] = React.useState(
    techs.length > 2 ? 1 : 0,
  ); //if we have 3 it's ok for now
  return (
    <div className="experts">
      <div className="experts__top">
        <div className="experts__top__ticket">
          <Badge text="Experts" />
        </div>
        <p className="experts__top__title">
          {tag === 'Design'
            ? 'Meet some our experts'
            : 'Meet some of our experts'}
        </p>
      </div>
      <div className="experts__top__technologies">
        {techs.map((tech, index) => (
          <p
            onClick={() => setPressedIndex(index)}
            className={[
              'experts__top__technologies__title',
              index === pressedIndex
                ? 'experts__top__technologies__title__pressed'
                : '',
            ].join(' ')}
          >
            {tech.title_en}
          </p>
        ))}
      </div>
      <div className={'experts__cont'}>
        <div className="experts__cont__publications">
          <MediaQuerySSR minWidth={1025}>
            <Experts experts={techs[pressedIndex].experts_en} />
          </MediaQuerySSR>
          <MediaQuerySSR maxWidth={1025}>
            <Slider {...settings}>
              <Experts experts={techs[pressedIndex]?.experts_en} />
            </Slider>
          </MediaQuerySSR>
        </div>
      </div>
    </div>
  );
};

export default Techs;
