import React, { useState, useContext } from 'react';
import Badge from '../Badge';
import CloseArrow from '../../img/close-arrow.inline.svg';
import OpenArrow from '../../img/arrow-open.inline.svg';

import './style.scss';
import { LangContext } from '../Layout';
import ReactMarkdown from 'react-markdown';

const Technology = ({ technology, open, close, isCms }) => {
  const { language } = useContext(LangContext);

  const [show, setShow] = useState(isCms ? 100000 : 300);

  const handleClick = () => {
    show === 300 ? setShow(10000) : setShow(300);
  };

  let keywords = technology.keywords.map(a => a.keyword);

  return (
    <div className="item">
      <div className="item__title">
        {technology['title_' + language].split(' ').map((word, index) =>
          keywords.includes(word) ? (
            <span className="item__title__special" key={index}>
              {word + ' '}
            </span>
          ) : (
            <span key={index}>{word + ' '}</span>
          ),
        )}
      </div>
      <div className="item__desc">
        <ReactMarkdown
          source={technology['desc_' + language].substring(0, show)}
        />
      </div>
      {!isCms && (
        <div className="item__button" onClick={handleClick}>
          {show === 300 ? (
            <div className="read-more">
              {open} <OpenArrow className="arrow" />
            </div>
          ) : (
            <div className="close">
              {close} <CloseArrow className="arrow" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const PSTechnology = ({ pstechnology, isCms }) => {
  const { language } = useContext(LangContext);

  return (
    <div className="technology">
      <div className="technology__top">
        <Badge text="Technology" />
        <div className="technology__top__title">
          {pstechnology['title_' + language]}
        </div>
        <div className="technology__top__headline">
          {pstechnology['headline_' + language]}
        </div>
      </div>
      <div className="technology__bottom">
        {pstechnology.technologies &&
          pstechnology.technologies.map((technology, index) => (
            <Technology
              key={index}
              technology={technology}
              open={pstechnology['open_' + language]}
              close={pstechnology['close_' + language]}
              isCms={isCms}
            />
          ))}
      </div>
    </div>
  );
};

export default PSTechnology;
